<template lang="pug">
.brand-wrapper.pt-0
  .d-flex.align-items-center.justify-content-end.p-3
    om-button.mr-3(secondary small @click="back()") {{ $t('globalFrequencyCap.back') }}
    om-button(primary small @click="save(selected)") {{ $t('globalFrequencyCap.next') }}
  .container
    global-frequency-cap(:value="selected" @selectCap="getCapIndex($event)")
</template>
<script>
  import { mapGetters } from 'vuex';
  import GET_CAMPAIGN_SETTINGS from '@/graphql/GetCampaignSettings.gql';
  import ADD_EXPERIMENTAL_SETTING from '@/graphql/AddExperimentalSetting.gql';
  import globalFrequencyCap from '@/mixins/globalFrequencyCap';
  import onboardingRedirect from '@/mixins/onboardingRedirect';

  const ONE_DAY = 24 * 60 * 60 * 1000;

  export default {
    mixins: [globalFrequencyCap, onboardingRedirect],
    data() {
      return {
        campaign: null,
        domain: null,
        selected: 1,
      };
    },
    computed: {
      ...mapGetters(['isShopifyDomain', 'shouldEnableShopifyAppEmbedForDomain', 'domains']),
      campaignId() {
        return this.$route.params.campaignId;
      },
      userId() {
        return this.$route.params.userId;
      },
      newParam() {
        return this.$route.params.new;
      },
    },
    async mounted() {
      await this.fetchCampaign();
    },
    methods: {
      getCapIndex($event) {
        this.selected = $event;
      },
      back() {
        this.$router.push({
          name: 'campaign_settings',
          params: { userId: this.userId, id: this.campaignId, new: this.newParam },
          query: { activeBox: 'trigger' },
        });
      },
      async fetchCampaign() {
        this.campaign = await this.$apollo.query({
          query: GET_CAMPAIGN_SETTINGS,
          variables: {
            campaignId: this.campaignId,
          },
        });
        this.domain = this.campaign.data.campaign.domain;
      },
      redirectToInsertCode({ domain, type }) {
        this.$router.push({
          name: 'code-insert-platform',
          params: {
            platform: type || 'other',
          },
          query: {
            ...this.$route.query,
            domain,
            campaign: this.campaignId,
          },
        });
      },
      chooseRedirectPath() {
        const domain = this.domains.find((d) => d.domain === this.domain);
        if (
          !this.isShopifyDomain(domain.domain) &&
          (!domain.lastRequestDate ||
            new Date(domain.lastRequestDate).getTime() < new Date().getTime() - ONE_DAY)
        ) {
          this.redirectToInsertCode(domain);
        } else if (this.shouldEnableShopifyAppEmbedForDomain(domain)) {
          this.$router.push({
            name: 'code-insert-platform-setup',
            params: {
              platform: 'shopify',
            },
            query: {
              ...this.$route.query,
              domain: domain.domain,
              campaign: this.campaignId,
            },
          });
        } else {
          this.redirectToCampaign();
        }
      },
      async save(index) {
        const preDefinedCapSettings = [this.safe, this.normal, this.off];

        try {
          await this.$apollo.mutate({
            mutation: ADD_EXPERIMENTAL_SETTING,
            variables: {
              input: preDefinedCapSettings[index],
            },
          });
          this.$notify({
            type: 'success',
            title: this.$t('notifications.saveSuccess'),
          });
          this.chooseRedirectPath();
        } catch (e) {
          this.$notify({
            type: 'error',
            title: this.$t('notifications.saveError'),
          });
          console.log('error', e);
        }
      },
    },
  };
</script>
